<template>
  <BModal
    id="modal-preview-ads"
    ref="modal-preview-ads"
    no-stacking
    centered
    hide-footer
    hide-header
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    size="lg"
    body-class="p-2"
    @shown="fetchData"
    @hidden="loading = true, resetData()"
  >
    <div class="flex flex-col gap-4 text-[#333]">
      <div class="font-semibold text-2xl  text-center">
        Preview Iklan
      </div>
      <BOverlay
        :show="loading"
        spinner-variant="primary"
      >
        <div class="grid grid-cols-2 !gap-4">
          <div class="border rounded-lg">
            <div class="flex items-center !gap-2 !p-3">
              <img
                src="https://storage.googleapis.com/komerce/komads-meta/preview.svg"
                alt="preview"
              >
              <div class="font-semibold ">
                Preview
              </div>
            </div>
            <div class="border-t flex justify-center">
              <div v-html="data.ad_preview_iframe" />
            </div>
          </div>
          <div class="flex flex-col !gap-4">
            <div class="border rounded-lg !p-3 space-y-3">
              <div class="font-semibold text-xl">
                Biaya Iklan
              </div>
              <div class="flex justify-between">
                <div class="">
                  Biaya harian
                </div>
                <div class="border-b text-2xl">
                  {{ IDR(data.daily_budget) }}
                </div>
              </div>
              <div class="border-b border-dashed border-gray-300" />
              <div class="grid grid-cols-2 !gap-3">
                <div class="!space-y-2 text-sm">
                  <div class="">
                    Tanggal Mulai
                  </div>
                  <div
                    class="flex justify-between items-center py-2.5 px-3.5 border rounded-lg"
                  >
                    {{ data.start_date ? DAY_MONTH_YEAR(data.start_date) : '-' }}
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/calendar.svg"
                      alt="calendar"
                      class="w-5"
                    >
                  </div>
                </div>
                <div class="!space-y-2 text-sm">
                  <div class="">
                    Tanggal Selesai
                  </div>
                  <div
                    class="flex justify-between items-center py-2.5 px-3.5 border rounded-lg"
                  >
                    {{ data.end_date ? DAY_MONTH_YEAR(data.end_date) : '-' }}
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/calendar.svg"
                      alt="calendar"
                      class="w-5"
                    >
                  </div>
                </div>
              </div>
              <div class="border rounded-lg">
                <div class="!gap-2 !p-3">
                  <div class="font-semibold ">
                    Perkiraan audiens iklan
                  </div>
                </div>
                <div
                  class="grid grid-cols-2 !gap-2 !p-3 border-t bg-[#F8F8F8] rounded-b-lg text-xs"
                >
                  <div class=" font-semibold">
                    Total biaya iklan
                  </div>
                  <div class=" font-semibold text-end">
                    {{ IDR(data.ad_budget) }} ({{ diffDays }} Hari)
                  </div>
                  <div class="">
                    Perkiraan audiens (/hari)
                  </div>
                  <div class=" text-end">
                    {{ data.estimate_audience_per_day }} Views
                  </div>
                </div>
              </div>
            </div>
            <div class="border rounded-lg">
              <div class="flex items-center !gap-2 !p-3">
                <img
                  src="https://storage.googleapis.com/komerce/komads-meta/saldo.svg"
                  alt="saldo"
                >
                <div class="font-semibold ">
                  Pembayaran Saldo Iklan
                </div>
              </div>
              <div
                class="flex items-center !gap-2 !p-3 border-t bg-[#F8F8F8] rounded-b-lg"
              >
                <div class=" text-2xl">
                  {{ IDR(data.ad_budget) }}
                </div>
              </div>
            </div>
            <div class="border rounded-lg">
              <div class="flex items-center !gap-2 !p-3">
                <img
                  src="https://storage.googleapis.com/komerce/komads-meta/estimasi.svg"
                  alt="audiens"
                >
                <div class="font-semibold ">
                  Estimasi Audiens
                </div>
              </div>
              <div
                class="flex items-center !gap-2 !p-3 border-t bg-[#F8F8F8] rounded-b-lg"
              >
                <div class=" text-2xl">
                  {{ data.estimate_audience }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </BOverlay>
      <BButton
        variant="primary"
        block
        size="md"
        type="reset"
        @click="$refs['modal-preview-ads'].hide()"
      >
        Tutup
      </BButton>
    </div>
  </BModal>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import { IDR } from '@/libs/currency'
import { DAY_MONTH_YEAR } from '@/libs/formatDate'
import { toast_error } from '@/libs/toastification'

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      data: {},

      DAY_MONTH_YEAR,
      IDR,
      toast_error,
    }
  },
  computed: {
    diffDays() {
      const { start_date, end_date } = this.data
      if (!start_date || !end_date) return 0

      const diff = new Date(end_date) - new Date(start_date)
      return diff > 0 ? Math.ceil(diff / (1000 * 3600 * 24)) : 0
    },
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        const {
          data: { data },
        } = await newAxiosIns.get(`/komads/api/v1/ads/${this.id}`)
        this.data = data
        this.loading = false
      } catch (error) {
        toast_error({
          title: 'Gagal',
          message: 'Gagal memuat detail iklan',
        })
        this.loading = false
      }
    },
    resetData() {
      this.data = {
        id: 0,
        daily_budget: 0,
        start_date: '',
        end_date: '',
        ad_budget: 0,
        title: '',
        page_name: '',
        estimate_audience: '0',
        estimate_audience_per_day: '0',
        ad_preview_iframe: '',
      }
    },
  },
}
</script>
