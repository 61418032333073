<template>
  <div>
    <BModal
      id="modal-topup-komads"
      ref="modal-topup-komads"
      v-model="visibleTopup"
      no-stacking
      centered
      hide-footer
      hide-header
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="md"
      body-class="p-2"
    >
      <div class="flex flex-col gap-4">
        <div class="text-center text-black text-xl font-semibold">
          {{ title }}
        </div>
        <ValidationObserver v-slot="{ invalid }">
          <div class="flex flex-col gap-4">
            <BFormGroup class="m-0">
              <template #label>
                <div class="text-black flex items-center">
                  <strong class="text-md font-semibold">Nominal</strong>
                  <strong class="text-primary">*</strong>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Nominal"
                :rules="{
                  required: true,
                  max_value: state.balance.balance,
                  min_value: 20000,
                }"
              >
                <BFormInput
                  v-model="nominal"
                  autofocus
                  placeholder="Masukkan nominal top up"
                  :state="errors.length > 0 ? false : null"
                  @keypress="isNumber($event)"
                  @input="formatCurrency"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
              <BCol
                v-if="visiblePossibilityTopup"
                class="justify-between p-0 mt-[5px]"
              >
                <div class="bg-[#FFEDED] p-1 mb-1 rounded-lg">
                  Maaf kami batasi maksimal penarikan akunmu agar tersisa saldo
                  setidaknya sebesar
                  <b class="text-danger">{{
                    IDR(state.possiblity.ideal_balance)
                  }}</b>
                  buat menghindari minus
                </div>
                <div class="border-dashed border-2 rounded-lg p-1 mb-1">
                  <p class="mb-1">
                    Angka ini didapat dari total nilai ongkir berangkat atas
                    orderan yang sudah diputuskan RETUR namun barangnya
                    <b>belum sampai</b> ke gudangmu yakni
                    <b>{{ IDR(state.possiblity.potency_retur) }}</b> total
                    tersebut kami bandingkan dengan total
                    <b>potensi pendapatanmu</b> yang akan diterima dari orderan
                    yang berstatus <b>DIKIRIM</b> yang pengirimannya sejauh ini
                    belum ada problem yakni
                    <b class="text-success">{{
                      IDR(state.possiblity.potency_income)
                    }}</b>
                  </p>
                  <p>
                    Di Komship enaknya tuh kalau Retur barang
                    <b>udah balik</b> lagi ke gudang baru kita minuskan ongkir
                    berangkatnya, belum lagi bisa di <b>claim</b> pula.
                  </p>
                </div>
              </BCol>
            </BFormGroup>
            <div class="flex items-center justify-between">
              <div class="text-[#626262]">
                Saldo Kompay
              </div>
              <BSpinner
                v-if="loading.balance"
                small
                variant="primary"
              />
              <div
                v-else
                class="font-semibold text-black"
              >
                {{ IDR(state.balance.balance) }}
              </div>
            </div>
            <BRow class="justify-center">
              <BCol cols="6">
                <BButton
                  variant="outline-primary"
                  block
                  size="md"
                  type="reset"
                  @click="onCloseTopup"
                >
                  Batal
                </BButton>
              </BCol>
              <BCol cols="6">
                <BButton
                  block
                  :variant="
                    invalid || allowedNextStep ? 'secondary' : 'primary'
                  "
                  type="submit"
                  :disabled="invalid || allowedNextStep"
                  size="md"
                  @click="onNextStep"
                >
                  <BSpinner
                    v-if="loading.possiblity"
                    small
                  />
                  Top Up Sekarang
                </BButton>
              </BCol>
            </BRow>
          </div>
        </ValidationObserver>
      </div>
    </BModal>

    <ModalPIN
      id="modal-verify-pin"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      title="Verifikasi PIN"
      description="Mohon verifikasi identitas kamu dengan masukan PIN"
      label-confirm-button="Konfirmasi"
      label-cancel-button="Kembali"
      @on-confirm-button="onSubmit"
      @on-cancel-button="onClosePin"
    />
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  max_value,
  min_value,
  extend,
} from 'vee-validate'
import { isNumber } from '@/libs/helpers'
import { required } from '@validations'
import { IDR } from '@/libs/currency'
import { komshipAxiosIns, newAxiosIns } from '@/libs/axios'
import { toast_error, toast_success } from '@/libs/toastification'
import ModalPIN from '@/views/components/modal/ModalPIN.vue'

extend('max_value', {
  validate: (value, { max }) => {
    const amount = parseFloat(value.replace(/[Rp.p]/g, ''))
    return amount <= max
  },
  message: (_, args) => 'Jumlah nominal tidak boleh melebihi saldo kompay',
})

extend('min_value', {
  validate: (value, { min }) => {
    const amount = parseFloat(value.replace(/[Rp.p]/g, ''))
    return amount >= min
  },
  message: (_, args) => `Minimal top up saldo Komads ${IDR(20000)}`,
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ModalPIN,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      max_value,
      min_value,
      isNumber,
      required,
      user: this.$store.state.auth.userData,
      nominal: '',
      state: {
        balance: {},
        possiblity: {},
      },
      visibleTopup: false,
      loading: {
        balance: false,
        possiblity: false,
        submit: false,
      },
      toast_error,
      IDR,
    }
  },
  computed: {
    normalizeNominal() {
      return Number(this.nominal.replace(/\D/g, ''))
    },
    isKomship() {
      return this.user.is_komship
    },
    loadingSubmit() {
      return this.loading.submit
    },
    allowedNextStep() {
      return this.loading.possiblity
    },
    visiblePossibilityTopup() {
      return this.state.possiblity.withdraw_possibilites === 0
    },
  },
  mounted() {
    this.getBalance()
  },
  methods: {
    async getBalance() {
      this.loading.balance = true
      const url = '/v1/dashboard/partner/balanceSummary'
      try {
        const { data } = await komshipAxiosIns.get(url)
        this.state.balance = data.data
        this.loading.balance = false
      } catch {
        this.loading.balance = false
        toast_error({
          title: 'Gagal',
          message: 'Gagal mengambil data saldo kompay',
        })
      }
    },
    async checkTopupPossibility() {
      this.loading.possiblity = true
      const url = `/v1/partner/withdrawal/check-possible-withdraw?withdrawal_request_nominal=${this.normalizeNominal}`

      try {
        const {
          data: { data },
        } = await komshipAxiosIns.get(url)
        this.loading.possiblity = false
        this.state.possiblity = data

        if (data.withdraw_possibilites) {
          this.visibleTopup = false
          this.$bvModal.show('modal-verify-pin')
        }
      } catch {
        this.loading.possiblity = false
        toast_error({
          title: 'Gagal',
          message: 'Gagal melakukan pengecekan top up',
        })
      }
    },
    async onSubmit(value) {
      const url = '/auth/api/v1/komads/topup'
      this.loading.submit = true

      const body = {
        nominal: this.normalizeNominal,
        pin: value,
      }

      try {
        this.loading.submit = false
        await newAxiosIns.post(url, body)
        toast_success({
          title: 'Berhasil',
          message: `Top Up saldo Komads sebesar ${IDR(
            this.normalizeNominal,
          )} berhasil.`,
        })
        this.sendMessage()
        this.nominal = ''
      } catch {
        toast_error({
          title: 'Gagal',
          message: `Top Up saldo Komads sebesar ${IDR(
            this.normalizeNominal,
          )} gagal. Silahkan coba lagi.`,
        })
        this.loading.submit = false
      } finally {
        this.loading.submit = false
        this.getBalance()
      }
    },
    onNextStep() {
      if (this.isKomship) {
        this.checkTopupPossibility()
      } else {
        this.visibleTopup = false
        this.$bvModal.show('modal-verify-pin')
      }
    },
    formatCurrency(value) {
      const newValue = Number(value.replace(/\D/g, ''))
      this.nominal = IDR(newValue)
    },
    onCloseTopup() {
      this.visibleTopup = false
      this.nominal = ''
    },
    onClosePin() {
      this.visibleTopup = true
    },
    sendMessage() {
      const message = { type: 'REFETCH_ADS_BALANCE' }
      const iframe = document.querySelector('#komads-container iframe')
      iframe.contentWindow.postMessage(message, '*')
    },
  },
}
</script>
